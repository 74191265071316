import React from 'react'
import { Link,useHistory  } from 'react-router-dom'
import {VStack,HStack,Divider,Text,Pressable,Stack,InputLeftElement,Input,Center,
Button,ScrollView,useMediaQuery,Menu,ChevronDownIcon,AddIcon,ThreeDotsIcon,ArrowBackIcon,ArrowForwardIcon,Spinner  } from 'native-base'
import {setUser,getUser} from '../../helper/authentication'
import step_process,{generate_scenes,generate_answers} from '../../assets/steps_process'
import genresandsubgenres from '../../assets/genresandsubgenres'
import preferences from '../../assets/preferences'
import outline_options from '../../assets/outline_options'
import styles_options from '../../assets/styles_options'
import scenes_formats from '../../assets/scenes.json'
import '../../App.css'
import Select from "react-select";
import Steps from 'react-steps';
import RenderConfiguration from '../settings_view'
import EditablePre from '../editable_pre'
import FileUpload from '../file_upload'
import ChatBox from '../ChatBox'
import HtmlViewer from '../HtmlViewer';
import Quiz from '../Quiz';
import htmlParser from 'html-react-parser';
import html2pdf from 'html2pdf.js';
// import {Banner300by250,Banner728by90} from "../Banner";
const { GoogleGenerativeAI } = require("@google/generative-ai");

const defaultStages = {
        0:{status:'start',title:'Summarization Options',  result:"configuration",type:'string'},
        1:{status:'start',title:'Choose Template',  result:"processing",type:'string'},
        2:{status:'start',title:'Content Result',  result:"content",type:'string'}, 
        // 2:{status:'start',title:'Introduction Details',result:""},
        // 3:{status:'start',title:'More on Chapter Content',result:""},
        // 4:{status:'start',title:'Paragraph Content',result:""},
        // 5:{status:'start',title:'Reference and Citation',result:""},
        // 6:{status:'start',title:'Book Blurb',result:""},
        // 7:{status:'start',title:'Author Bio and Acknowledgments',result:""},
        }

const defaultScenes = [ 
    ] 



export default function SignInPage() {
     const [show, setShow] = React.useState(false);
     const [isLoading,setIsLoading] = React.useState(false);
     const [askLoading,setAskLoading] = React.useState(false);
     const [step, setStep] = React.useState(1);
     const [currentStage, setCurrentStage] = React.useState();
     const [done, setDone] = React.useState(false);
     const [stages, setStages] = React.useState(defaultStages);
     const [state, setState] = React.useState({
    "summary_length": {
        "value": "Brief (Highlight the key points while keeping it concise and focused)",
        "label": "Brief (Highlight the key points while keeping it concise and focused)"
    },"summary_tone_style": {
        "value": "Generate summaries for general documents or content.",
        "label": "Generate summaries for general documents or content."
    },  "summary_focus": {
        "value": "Key Themes (Highlight the main topics and group related information under each theme)",
        "label": "Key Themes (Highlight the main topics and group related information under each theme)"
    },
    "summary_language": {
        "value": "Auto Detect",
        "label": "Auto Detect"
    },
    "summarization_model": {
        "value": "Extractive (Summarize by selecting key sentences without altering the original language.)",
        "label": "Extractive (Summarize by selecting key sentences without altering the original language.)"
    },
    'features':{
        'value':"Document"
    }
});
     const [showContent, setShowContent] = React.useState(false);
     const [showContentKey, setShowContentKey] = React.useState({title:'',key:""});
     const [chapters, setChapters] = React.useState(defaultScenes);
     const [summariseScenes, setSummariseScenes] = React.useState(defaultScenes);
     const [chapter, setChapter] = React.useState(1);
     const [scene, setScene] = React.useState(1);
     const [loading_label, setLoadingLabel] = React.useState("Summarizing Content" );

 


const history = useHistory();



const getHistoryFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const historyValue = urlParams.get('history');
  
  if (historyValue) {
    try {
      return historyValue; // If the value is a JSON string, parse it
    } catch (error) {
      console.error("Error parsing 'history' from URL:", error);
      return null;
    }
  }
  return null; // Return null if the 'history' parameter is not found
};




const step_json = [
    {step:1,label:'Upload'},
    {step:2,label:'Options'},
    {step:3,label:'Template'}, 
    {step:4,label:'Result'},
    ].map(e=>({
        "text": e.label,
        "isActive": e.step===step,
        "isDone": e.step<step
    }))



const selectedId =  encodeURIComponent(getHistoryFromURL())

React.useEffect(()=>{

console.log('selectedId',selectedId)

     if (selectedId){
const storedHistory = JSON.parse(localStorage.getItem('history')) || {};
 const content = storedHistory[selectedId];
    if (content) {
setState(content)
 setStep(4)

    }

     } else {
         setStep(1)
     }
  
},[selectedId]) 








const MAX_TOKENS = 80000;  // Adjust based on Gemini's prompt size limit
const genAI = new GoogleGenerativeAI(process.env.API_KEY||'AIzaSyDtJeDAr4hLewHq53eGUq6jVuaTe6juBV4');
const model = genAI.getGenerativeModel({ model: process.env.API_VERSION || "gemini-1.5-flash"});



  const updateState = (label,value) => {
setState(prev=>({...prev,[label]:value}))
  }


function splitText(text, delimiters = ['\n', '\t'], words = []) {
  const escapedDelimiters = delimiters.map(d => d.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'));
  const pattern = [...escapedDelimiters, ...words].join('|');
  const regex = new RegExp(`(${pattern})`, 'gi');
  return text.split(regex).filter(part => part.trim() !== ''); // Remove empty splits
}





 // Function to split the text into paragraphs or semantic chunks
  function splitTextIntoParagraphs(text, maxTokens) {
      setIsLoading(true);  // Start loading
    // setLoadingLabel("Splitting content into parts...",text)
    const paragraphs = splitText(text, ['\n', '\t', '.', ';','.\n'], ['Chapter', 'Section','page']);  // Assuming paragraphs are separated by two newlines
    const parts = [];
    let currentPart = '';

    for (const paragraph of paragraphs) {
      if ((currentPart + paragraph).length > maxTokens) {
        parts.push(currentPart);  // Push current part when limit is reached
        currentPart = '';  // Start a new part
      }
      currentPart += paragraph + '\n\n';  // Add paragraph to the current part
    }

    if (currentPart.trim()) {
      parts.push(currentPart);  // Push the last part
    }

    console.log('parts',parts.length)

    return parts;
  }





function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


  // Function to recursively summarize text parts
  async function recursiveSummarize(parts) {
    const summarizedParts = [];
    let part_num = 1
     // setIsLoading(true);  // Start loading
    for (const part of parts) {
          setLoadingLabel(`Summarizing, part ${part_num} of ${parts.length} ...`)
      const summary = await handleGeminiRequest(part);

      if (summary) {
        summarizedParts.push(summary);
      }

         part_num++; // Increment part number for labeling
    await delay(5000);

    }

    setLoadingLabel('Processing and merging contents')
     setIsLoading(true);  // Start loading

        // console.log('summary results',summarizedParts)
    // If the summary itself is too long, split and summarize recursively
let updatedParts = []
   summarizedParts.forEach((sp,index)=> {
    let newsp = sp
console.log(index,removeConsecutiveJSONBr(newsp))
    if (index == 0){
       updatedParts =   JSON.parse(removeConsecutiveJSONBr(newsp))
    } else {
       updatedParts =  [...updatedParts, ...JSON.parse(removeConsecutiveJSONBr(newsp)).filter(e=>e.section.toLowerCase() !== 'title' && e.section.toLowerCase() !== 'introduction' )]

    }



      })


   
    return JSON.stringify(updatedParts);  // Return final summary if small enough
  }







//   const handleGeminiRequest = (name,prompt,callback=f=>f) => {
//     setIsLoading(true);
//     console.log(name,prompt)
 
//     const result = model.generateContent(prompt).then(
//         response => {
//             setIsLoading(false);
// // console.log(response)
// console.log(response.response['candidates'][0]['content']['parts'][0].text)

//  // callback(response)

//         }).catch(error => {
//                 setIsLoading(false);
//                 console.error("Error:", error)
//             });


 
//   } 

const removeConsecutiveJSONBr = (text) => {

    return text
        .replace('json', '')
        // .replace(/<br\/>/g, '<br/>') 
        .replace(/`/g, '') // Remove backticks
        // .replace(/\\/g, "") // Remove backslashes
        //   .replace(/\\n/g, "<br/>")
        //   .replace(/\n/g, '<br/>')
        .trim();
};



 
 const handleGeminiRequest = async (text) => {
    try {
       

        const result = await model.generateContent(generate_scenes( {...state,content:text })   );

        const generatedText = result.response['candidates'][0]['content']['parts'][0].text;
        // console.log(generatedText);
        return generatedText;

    } catch (error) {
        setIsLoading(false);
        console.error("Error:", error);
        throw error;  // Optionally throw the error to handle it outside this function
    }
};





 
 const handleAskResponse = async (text) => {
    try {
        setAskLoading(true);  // Start loading

        const result = await model.generateContent(generate_answers(text, state)   );
        setAskLoading(false);
        const generatedText = result.response['candidates'][0]['content']['parts'][0].text;
        // console.log(generatedText);
        updateState('askResponse', generatedText.replace('**Response Format:**',""));


    } catch (error) {
        setAskLoading(false);
        console.error("Error:", error);
        throw error;  // Optionally throw the error to handle it outside this function
    }
};




const getChapter = (ch)=> ch.indexOf("-") >= 0 ? ch.split("-"):['1','1']

const goback=()=>setStep(step-1)

const reGenerate=(key)=>{
// 
}

const update_stages=(key=0,status='done')=>{
    setCurrentStage(key)
     const new_stages = {...stages}
        new_stages[String(key)].status = status
        setStages(new_stages)
}


function removeConsecutiveBr(inputText) { 
   return inputText.replace(/(\*+\s*[^:]+\:\s*\*+)/g, (match) => {
        // Extract the text without the asterisks
        const text = match.replace(/\*\*/g, '');
        return `<br/><strong>${text}</strong>`;
    });
}


const goforward = async (m_step) => {
    if (m_step || step == 3) {
  
          try {
    // console.log('textsf',state.content)
 
    const initialParts = splitTextIntoParagraphs( state.content, MAX_TOKENS);
  let content = await recursiveSummarize(initialParts);

  setLoadingLabel('Summarization completed')
  setIsLoading(false)
        updateState('summarize_content', removeConsecutiveBr(content));
                setStep(4);

            // update_stages(3, 'progress');
          // console.log(state)
            // handleGeminiRequest('summarize_content', generate_scenes( state), (content) => {
          
              
            // });

        
        } catch (error) {
            console.error("Error:", error);
        }
    } else {
        if (!state.documentFileName){
            alert("Provide content to continue")
        } else {

        setStep(step + 1);
        }
    }
};


const deleteContent = (id) => {
  const history = JSON.parse(localStorage.getItem('history')) || {};
  if (history[id]) {
    delete history[id];
    localStorage.setItem('history', JSON.stringify(history));
  } 
};





const renderHome=()=>{
    return (
        <>
<Center mt={-2}  h={'90vh'}>
 
    <button  className="big_button button-63" onClick={e=>setStep(step-1)} style={{
                        textDecoration:'none',width:200,height:'48px',borderRadius:'17px'
                    }} ><span>Home </span> </button>
                   
</Center>

</>)
}



const RenderDropdown=({label="Choose your color", value="",setValue=f=>f,optionList=[],
    placeholder="Select Option(s)",isMulti=false})=>{

const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);



return (
 <div className="app" >
<Text   style={{fontSize:"1.3rem", }} >{label}:</Text>
     <div className="dropdown-container mt-3">
      <Select
        options={optionList}
        placeholder={placeholder}
        value={value}
        onChange={setValue}
         isSearchable={isPortrait ? false: true}
         isMulti={isMulti}
      />
    </div>
    </div>
    )

}

const RenderLoading=({label="",w='200px'})=>{
    return (
        <>
<VStack mt={3}    alignItems='center' justifyContent='center'  >
 

   <svg    
style={{    display: "block", shapeRendering: "auto",}} width={w} height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<g transform="translate(50 42)">
  <g transform="scale(0.8)">
    <g transform="translate(-50 -50)">
      <polygon fill="#e15b64" points="72.5 50 50 11 27.5 50 50 50">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 38.5;360 50 38.5" keyTimes="0;1"></animateTransform>
      </polygon>
      <polygon fill="#f8b26a" points="5 89 50 89 27.5 50">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 27.5 77.5;360 27.5 77.5" keyTimes="0;1"></animateTransform>
      </polygon>
      <polygon fill="#abbd81" points="72.5 50 50 89 95 89">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 72.5 77.5;360 72 77.5" keyTimes="0;1"></animateTransform>
      </polygon>
    </g>
  </g>
</g>
 </svg> 
 <Text  fontSize= {isPortrait?'sm': "2xl"} fontWeight='bold' > {label} </Text>
                   
</VStack>

</>)
}


const renderStepOne=()=>{


    return (
        <>
 
<VStack alignItems='center'     w='100%'     >
<Divider />
<HStack space={1} alignItems='center' >
 <AddIcon />   <Text my={2} bold   style={{fontSize: isPortrait ?'.8rem' : "1rem", marginBottom:'10px', textAlign:'center',
 fontFamily: "'Noto Sans JP', serif"  }}> Upload Content</Text>
    </HStack>
 <Divider mb={ isPortrait ? 0: 5} />
 <FileUpload updateState={updateState} />
</VStack>

</>)
}




const renderStepTwo=()=>{

  


    return (
        <>
<VStack alignItems='center'    >


<VStack space={4}  w={ isPortrait ?'95%': '95%'}  >
<VStack   alignItems='center'   >
<Divider />
<HStack space={2} alignItems='center' >
  <Text my={2} bold style={{fontSize: isPortrait ? '15px': "1.2rem", marginBottom:'10px', textAlign:'center',fontFamily: "'Noto Sans JP', serif"   }}> Summarization Options</Text>
   <ThreeDotsIcon size="1.5rem" mt={1} mx={2} /> 
    </HStack>
 <Divider />
</VStack>

<VStack  bg='white' space={4} pb={2} mt={ isPortrait ? 2: 6}  style={{boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px"}}>

 <ScrollView horizontal   showsHorizontalScrollIndicator={false}   keyboardShouldPersistTaps="always"  
      contentContainerStyle={{ flexGrow: 1 }} sty >

 <HStack sty space={0} my={-5} ml={-8} justifyContent="flex-start" px={0} bg="gray.100" borderRadius="lg" w='100%' >
     
      <div className="container">
      <div className="tabs nav-item">
      { [...(preferences['Language'].slice(0,5).indexOf({...state.summary_language}.value ) < 0 ? [{...state.summary_language}.value ]:[]),    ...preferences['Language'].slice(0,5)].map((language) => ( 
        <button style={{borderTopLeftRadius:'8px',borderTopRightRadius:'8px',fontFamily: "'Noto Sans JP', serif",
fontSize: isPortrait ?'13px':null, minWidth:'130px'
    }}
          onClick={() =>  updateState('summary_language',({value:language,label:language}))  }
          className={` py-3 tab ${{...state.summary_language}.value === language ? 'active' : ''}`}
        >
          {language}
        </button>
      
      ))}
<button 
          className={` tab  `}
        >

      <Menu px={2}
            w="150"
            trigger={(triggerProps) => (
              <Pressable {...triggerProps}  className="tab" >
                <HStack alignItems='center' space={4} > More
                 <ChevronDownIcon color='gray' /></HStack>
              </Pressable>
            )}
          >
            {preferences['Language'].slice(5,50).map((language) => (
              <Menu.Item key={language} onPress={() => updateState('summary_language',({value:language,label:language})) } 
              style={{fontFamily: "'Noto Sans JP', serif"}}>
                {language}
              </Menu.Item>
            ))}
          </Menu>

          </button>



         </div>
      </div>


      


    </HStack>

  </ScrollView>




 <ScrollView  horizontal  showsHorizontalScrollIndicator={false}  keyboardShouldPersistTaps="always"  
      contentContainerStyle={{ flexGrow: 1 }} style={{border:'0px solid #eee', 
     }} >


 <HStack py={ isPortrait ? 1: 5} space={isPortrait ? 2:4} alignItems='center'  justifyContent="flex-start" px={ isPortrait ? 2: 4} bg="gray.100"   w='100%' >
     
 <Text   style={{fontSize: isPortrait ? '.8rem': "1rem"  ,fontWeight:'bold',minWidth: isPortrait ? '3%':'5%',color:'black' ,fontFamily: "'Noto Sans JP', serif" }} mr={6} > Modes:</Text>

  {preferences['Summary Length'].map((e) => (
        <Pressable
          key={e.value} style={{minWidth:'100px',textAlign:'left'}}
          onPress={() => {
           updateState('summary_length',e) 
updateState('custom_summary_length', false)
}
           }  
          px={4}
          py={isPortrait ? 1:2} 
          borderRadius="24px"
          bg={ ({...state.summary_length}.value === e.value && !{...state.custom_summary_length}.value ) ? 'primary.500' : 'gray.300'}
          _pressed={{ bg: 'primary.700' }}
        >
          <Text style={{fontFamily: "'Noto Sans JP', serif"}} fontSize="14px" color={({...state.summary_length}.value === e.value && !{...state.custom_summary_length}.value ) ? 'white' : 'black'}  >
            {e.label}
          </Text>
        </Pressable>
      ))}

   
    <Menu px={2}
            w="150"
            trigger={(triggerProps) => (
              <Pressable {...triggerProps} px={4} py={2} borderRadius="md" 

               bg={  {...state.custom_summary_length}.value  ? 'primary.500' : 'gray.300'}
          _pressed={{ bg: 'primary.700' }}

            borderRadius="24px" 
              style={{minWidth:'100px',textAlign:'center'}}>
                <HStack alignItems='center' space={2} ><Text color={{...state.custom_summary_length}.value  ? 'white' : 'black'} 

                style={{fontFamily: "'Noto Sans JP', serif",fontSize:'14px'}}>Custom {{...state.custom_summary_length}.value ?"-":""} {{...state.custom_summary_length}.value}</Text>
                 <ChevronDownIcon color={{...state.custom_summary_length}.value  ? 'white' : 'black'}   /></HStack>
              </Pressable>
            )}
          >
            {preferences['Custom Summary Length'].map((e) => (
              <Menu.Item key={e} onPress={() => updateState('custom_summary_length',({value:e,label:e})) }>
                {e} 
              </Menu.Item>
            ))}
          </Menu>




    </HStack>

  </ScrollView>


 



 <ScrollView horizontal  showsHorizontalScrollIndicator={false}  keyboardShouldPersistTaps="always"  
      contentContainerStyle={{ flexGrow: 1 }} style={{border:'0px solid silver', 
   }} >

 <HStack py={ isPortrait ? 2: 5} space={isPortrait ? 2:4} alignItems='center'  justifyContent="flex-start" px={ isPortrait ? 2: 4} bg="gray.100"     w='100%' >
     
 <Text   style={{fontSize: isPortrait ? '.8rem': "1rem"   ,fontWeight:'bold' ,minWidth:isPortrait ? '3%':'5%',color:'black' ,fontFamily: "'Noto Sans JP', serif"}} mr={6} > Focus:</Text>

  {preferences['Summary Focus'].map((e) => (
        <Pressable
          key={e.value} style={{minWidth:'100px',textAlign:'center'}}
          onPress={() =>  updateState('summary_focus',e)  }  
           px={4}
          py={isPortrait ? 1:2} 
          borderRadius="24px"
          bg={{...state.summary_focus}.value === e.value ? 'primary.500' : 'gray.300'}
          _pressed={{ bg: 'primary.700' }}
        >
          <Text style={{fontFamily: "'Noto Sans JP', serif"}}  fontSize="14px" color={{...state.summary_focus}.value === e.value ? 'white' : 'black'}  >
            {e.label}
          </Text>
        </Pressable>
      ))}

   


    </HStack>

  </ScrollView>



 <ScrollView mb={3} horizontal  showsHorizontalScrollIndicator={false}  keyboardShouldPersistTaps="always"  
      contentContainerStyle={{ flexGrow: 1 }} style={{border:'0px solid silver', }} >


 <HStack py={ isPortrait ? 2: 5} space={isPortrait ? 2:4} alignItems='center'  justifyContent="flex-start" px={ isPortrait ? 2: 4} bg="gray.100"  w='100%' >
     
 <Text   style={{fontSize: isPortrait ? '.8rem': "1rem"   ,fontWeight:'bold',minWidth:isPortrait ? '3%':'5%',color:'black',fontFamily: "'Noto Sans JP', serif" }} mr={6} > Model:</Text>

  {preferences['Summary Model'].map((e) => (
        <Pressable
          key={e.value} style={{minWidth:'100px',textAlign:'center'}}
          onPress={() =>  updateState('summarization_model',e)  }  
          px={4}
          py={isPortrait ? 1:2} 
          borderRadius="24px"
          bg={{...state.summarization_model}.value === e.value ? 'primary.500' : 'gray.300'}
          _pressed={{ bg: 'primary.700' }}
        >
          <Text style={{fontFamily: "'Noto Sans JP', serif"}} fontSize="14px" color={{...state.summarization_model}.value === e.value ? 'white' : 'black'}  >
            {e.label}
          </Text>
        </Pressable>
      ))}

   


    </HStack>

  </ScrollView>




 
</VStack>
</VStack>
</VStack>

</>)
}

const renderStepThree=()=>{

   
    return (
        <>
<Center   >

<VStack space={12}  w={  '95%' } mt={2}  >

<VStack   alignItems='center'   >
<Divider />
<HStack space={2} alignItems='center' >
  <Text bold my={2}  style={{fontSize: isPortrait ? '14px': "1.2rem", marginBottom:'10px', textAlign:'center',fontFamily: "'Noto Sans JP', serif"   }}> Select Template</Text>
   <ThreeDotsIcon size="1.5rem" mt={1} mx={2} /> 
    </HStack>
 <Divider />
</VStack>

<VStack space={4} pb={2} mt={-8} h={isPortrait? '58vh':null}  style={{boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px"}}>

<ScrollView 
        flex={1} 
        px={isPortrait ?0:4} 
        pb={6} 
        bg="gray.100"
        maxH="600px"  // Ensures the list scrolls instead of overflowing the screen
      >
 <VStack space={2} px={isPortrait ?0: 4} py={ isPortrait ?0:6} bg="gray.100" flex={1}>
      {preferences['Summary Tone'].map((item, index) => (
        <Pressable key={index} onPress={() => updateState('summary_tone_style',({label:item.title,value:item.value}))} 
         

          shadow={1}
              _hover={{ bg: "blue.50" }} 
              _pressed={{ bg: "blue.100" }}
              transition="background-color 0.2s"

          bg={item.value=== {...state.summary_tone_style}.value ? "blue.100":  'white'} p={4} borderRadius="lg"  
         >
          <HStack space={3} alignItems="center">
            <VStack flex={1} space={1}>
              <Text fontSize={isPortrait ?'sm':"md"} fontWeight="bold" color="black" style={{fontFamily: "'Noto Sans JP', serif"}} >{item.title}</Text>
              <Text fontSize="sm" color="gray.500" style={{fontSize:isPortrait ?'13px':null, fontFamily: "'Noto Sans JP', serif"}} >{item.value}</Text>
            </VStack>
            <AddIcon />
          </HStack>
        </Pressable>
      ))}
    </VStack>
</ScrollView>



</VStack>

 

</VStack>
</Center>

</>)
}



const exportButtonStyle = {
  padding: '7px 15px',
  backgroundColor: '#4CAF50',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '5px'
};

// Optional: Add some styling for the export button
const saveButtonStyle = {
  padding: '7px 15px',
  backgroundColor: '#1e53a2',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '5px',
  'width': '140px'
};




const RenderStepFour=()=>{

  const contentRef = React.useRef(null);

 const [activeIndex, setActiveIndex] = React.useState(0);
 const [isquiz, setIsquiz] = React.useState(false);
 const [pdfLoading, setPdfLoading] = React.useState(false);
 
 const toggleSection = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


const data_content =  JSON.parse(removeConsecutiveJSONBr(state.summarize_content||"[]"))
 // Function to export content to PDF
 



const handleExportToPDF = async () => {
    try{


 setPdfLoading(true)
  const container = document.createElement('div');
  // Loop through each section and create HTML content for it
  data_content.forEach((section, index) => {
    // Create a section wrapper
    const sectionElement = document.createElement('div');
    sectionElement.style.marginBottom = '20px'; // Add spacing between sections
    
    // Create title element for the section
    const titleElement = document.createElement('h2');
    titleElement.textContent = section.section;
    sectionElement.appendChild(titleElement);

    // Create content element for the section
    const contentElement = document.createElement('p');
    contentElement.innerHTML  =  section.content; 
    sectionElement.appendChild(contentElement);

    // Append the section to the container
    container.appendChild(sectionElement);
  });

  // Append the container to the body temporarily (it won't be shown to the user)
  document.body.appendChild(container);

  // Now use html2pdf to generate the PDF from the dynamically created content
  const opt = {
    margin: [0.5, 0.5, 0.5, 0.5], // Margins: top, right, bottom, left
    filename: 'summary_content.pdf',
    html2canvas: {
      scale: 2,  // Higher scale for better quality
      useCORS: true  // Enable Cross-Origin Resource Sharing
    },
    jsPDF: {
      orientation: 'portrait',
      unit: 'in',
      format: 'letter'  // Paper format
    }
  };

  html2pdf().from(container).set(opt).save();

  // Clean up by removing the container after PDF is generated
  document.body.removeChild(container);
 setPdfLoading(false)
     }  catch (error) {
 setPdfLoading(false)
            console.error("Error:", error);
        }
};




const saveContent = () => {
    console.log('data_content',data_content)
    if (data_content.length){

  const id = encodeURIComponent(data_content[0].content.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 40));
  const history = JSON.parse(localStorage.getItem('history')) || {};
  history[id] = {...state, content:state.summarize_content};
  localStorage.setItem('history', JSON.stringify(history));
  alert("Content saved to history.");
    }
};

const quizMe = (  ) => {
updateState('features',({value: 'Quiz Me',label: "Quiz Me" })) 
};


    return (
        <>


<Center   >


<VStack space={4}  w={'90%'} >
<VStack   alignItems='center'   >
<Divider />

 { isPortrait ?


<VStack space={2} justifyContent='space-between' w='100%' >

{isLandScape && <HStack space={1} alignItems='center' pl={3}  >
  <Text my={2}  style={{fontSize: isPortrait ? '16px': "1.2rem", marginBottom:'10px', textAlign:'center', color:'gray',fontFamily: "'Noto Sans JP', serif"}}>
   { {...state.summary_tone_style}.label} </Text>
   <svg  width="16" height="16" fill="currentColor" class="bi bi-ui-radios-grid" viewBox="0 0 16 16">
  <path d="M3.5 15a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m9-9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m0 9a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5M16 3.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-9 9a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m5.5 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m-9-11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 2a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7"/>
</svg>

    </HStack>}

<HStack space={2} alignItems='center' justifyContent='space-around' mt={4} mb={isPortrait ?3:0} >

    <Button.Group isAttached colorScheme="blue" mx={{
    base: "auto",
    md: 0
  }} size="sm">

     <Menu px={2}
            trigger={(triggerProps) => (
              <Pressable  {...triggerProps}  className="tab" px={4}  // Horizontal padding
      py={1}  // Vertical padding
      shadow={3}  // Subtle shadow
      border={'1px solid silver'}  // Subtle shadow
      bg="blue.800"
      _hover={{
        bg: "blue.600", // Darker blue on hover
      }}
     
      style={{ color:"white"}} >
     
                <HStack alignItems='center' space={4} pt={1} >
                    <svg  width="16" height="16" fill="white" class="bi bi-question-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-1.556 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
</svg>
 Quiz Me
                 <ChevronDownIcon color='gray' /></HStack>
              </Pressable>
            )}
          >
            { ['Easy','Medium','Hard'].map((diff) => (
              <Menu.Item key={diff} key={diff} onPress={() => {
                localStorage.removeItem('has_questions')
                updateState('difficulty',({value:diff,label:diff}))
                quizMe()

                 }} 
              style={{fontFamily: "'Noto Sans JP', serif"}}>
                {diff}
              </Menu.Item>
            ))}
          </Menu>



          <Button variant='outline'
      onPress={saveContent}
      px={4}  // Horizontal padding
      py={1} h={37}  // Vertical padding
      shadow={3}  // Subtle shadow
       
      _hover={{
        bg: "blue.600", // Darker blue on hover
      }}
     
      style={{border:"1px solid silver",borderRadius:0}}
      leftIcon={
       

<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
  <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z"/>
</svg>
 
      }
      leftIconSpacing={2}  // Space between the icon and text
    >
     {pdfLoading ? <Spinner size='sm' />: <Text fontSize="md"  >Save to Cloud</Text>}
    </Button>

    </Button.Group>
    </HStack>


    </VStack>


    :



<HStack space={2} justifyContent='space-between' w='100%' >

<HStack space={1} alignItems='center' pl={3} style={{borderLeft:'1px solid silver'}} >
  <Text my={2}  style={{fontSize: isPortrait ? '16px': "1.2rem", marginBottom:'10px', textAlign:'center', color:'gray',fontFamily: "'Noto Sans JP', serif"}}>
   { {...state.summary_tone_style}.label} </Text>
   <svg  width="16" height="16" fill="currentColor" class="bi bi-ui-radios-grid" viewBox="0 0 16 16">
  <path d="M3.5 15a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m9-9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m0 9a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5M16 3.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-9 9a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m5.5 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m-9-11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 2a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7"/>
</svg>

    </HStack>

<HStack space={2} alignItems='center' >

               <Menu px={2}
            w="100"
            trigger={(triggerProps) => (
              <Pressable  {...triggerProps}  className="tab" px={4}  // Horizontal padding
      py={1}  // Vertical padding
      shadow={3}  // Subtle shadow
      border={'1px solid silver'}  // Subtle shadow
      bg="blue.800"
      _hover={{
        bg: "blue.600", // Darker blue on hover
      }}
      _focus={{
        bg: "blue.600", // Darker blue when focused
      }}
      style={{border:"1px solid silver",color:"white"}} >
     
                <HStack alignItems='center' space={4} >
                    <svg  width="16" height="16" fill="white" class="bi bi-question-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-1.556 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
</svg>
 Quiz Me
                 <ChevronDownIcon color='gray' /></HStack>
              </Pressable>
            )}
          >
            { ['Easy','Medium','Hard'].map((diff) => (
              <Menu.Item key={diff} onPress={() => {
                localStorage.removeItem('has_questions')
                updateState('difficulty',({value:diff,label:diff}))
                quizMe()

                 }} 
              style={{fontFamily: "'Noto Sans JP', serif"}}>
                {diff}
              </Menu.Item>
            ))}
          </Menu>



          <Button
      onPress={saveContent}
      px={4}  // Horizontal padding
      py={1} h={37}  // Vertical padding
      shadow={3}  // Subtle shadow
      border={'1px solid silver'}  // Subtle shadow
      bg="blue.800"
      _hover={{
        bg: "blue.600", // Darker blue on hover
      }}
      _focus={{
        bg: "blue.600", // Darker blue when focused
      }}
      style={{border:"1px solid silver",borderRadius:0}}
      leftIcon={
       
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
  <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z"/>
</svg>
 
      }
      leftIconSpacing={2}  // Space between the icon and text
    >
         {pdfLoading ? <Spinner size='sm' />: <Text fontSize="md" color='white' >Save to Cloud</Text>}
    </Button>
    </HStack>


    </HStack>

}
 <Divider />
</VStack>


<VStack  bg='white' space={4}    >

 <ScrollView horizontal   showsHorizontalScrollIndicator={false}   keyboardShouldPersistTaps="always"  
      contentContainerStyle={{ flexGrow: 1 }}  >
 <HStack   space={0} my={-5} ml={-8} justifyContent="flex-start" px={0}   borderRadius="lg" w={ isPortrait ? '110%': '80%'} 
  >
      <div className="container" >
      <div className="tabs nav-item">
      {   [{label:'Document'},{'label': {...state.features}.value === 'Quiz Me'?'Quiz Me':null},{'label':'Ask Document AI'}].filter(e=>e.label).map((e) => ( 
        <button style={{borderTopLeftRadius:'8px',borderTopRightRadius:'8px',fontFamily: "'Noto Sans JP', serif",
      }}
          onClick={() =>{

           updateState('features',({value:e.label,label:e.label})) 
localStorage.removeItem('has_questions')
            }}
          className={` py-${ isPortrait ?'2': '3'} tab ${{...state.features}.value === e.label ? 'active' : ''}`}
        >
        <HStack space={ isPortrait ?2: 3} alignItems='center' > 
        {
            e.label === 'Document' ?
        <svg  width="16" height="16" fill="currentColor" class="bi bi-list-columns" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 0 .5m13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-13 2A.5.5 0 0 1 .5 2h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-13 2A.5.5 0 0 1 .5 4h10a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5m13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-13 2A.5.5 0 0 1 .5 6h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-13 2A.5.5 0 0 1 .5 8h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-13 2a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-13 2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-13 2a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5m13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
</svg>:  e.label === 'Quiz Me'  ? 
<svg   width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-1.556 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
</svg>

:   

<svg   width="20" height="20" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
  <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
  <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-1.588-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2"/>
</svg>


}
       <span style={{fontFamily: "'Noto Sans JP', serif", fontSize:isPortrait? "12px":'16px'}}>  {e.label}  </span>
        </HStack>
        </button>
      
      ))}
      </div>
      </div>
</HStack>
</ScrollView>

</VStack>

 { {...state.features}.value === 'Document'  ? 
  <div className="accordion-container accordion"  id="accordionExample" style={{maxHeight:isPortrait ?'50vh': '70vh',overflowX:'hidden',overflowY:'scroll'}}>
      {[...data_content].map((section, index) => (
       <div key={index} className="accordion-item">
          <h2 className="accordion-header" id={`heading${index}`}>
            <button
              className={`accordion-button ${activeIndex === index ? "" : "collapsed"}`}
              type="button"
              onClick={() => toggleSection(index)}
              aria-expanded={activeIndex === index ? "true" : "false"}
              aria-controls={`collapse${index}`} style={{fontFamily: "'Noto Sans JP', serif" }}
            >
            <HStack maxWidth={isPortrait ?'98%':'90%'} alignItems='center' space={2}>  <span  style={{fontWeight:'bold',fontFamily: "'Noto Sans JP', serif",
            fontSize:isPortrait ? '13px':null }}>Section {index+1}: </span><span style={{fontFamily: "'Noto Sans JP', serif", fontSize:isPortrait ? '13px':null  }}>{section.section.replace('<strong>',"").replace('</strong>',"")} </span></HStack>
            </button>
          </h2>
          <div
            id={`collapse${index}`}
            className={`accordion-collapse collapse ${activeIndex === index ? "show" : ""}`}
            aria-labelledby={`heading${index}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body" style={{background:'#eee'}} >
              <HtmlViewer htmlContent={section.content}  />
            </div>
          </div>
        </div>
      ))}
    </div>
    : {...state.features}.value === 'Quiz Me' ? 
<Quiz updateState={updateState} model={model} state={state} RenderLoading={RenderLoading} 
 


 />
    :
<VStack> <ChatBox state={state} handleChatRequest={handleAskResponse} askLoading={askLoading} 
updateState={updateState} /> </VStack>
}


 
 

  

</VStack>
</Center>

</>)
}

 


const RenderSideContent=({})=>{
    return (
        <>

        <ScrollView     h='88vh' style={{borderRight:'1px solid silver'}}  >
        <HStack justifyContent='space-between' alignItems='center' pr={3}  >
 
                    <button onClick={e=>{
                        setStep(1)
                        setStages(defaultStages)
                        setState({})
                    }} className=" btn   border-0 text-left mr-2 text-white  " style={{
                        textDecoration:'none', width:'100%',height:'48px',textAlign:'left',borderRadius:'0px',
                    }} ><span> <span className='mr-2' style={{fontSize:'19px'}}>+ </span> New Novel </span> </button>
             
 

</HStack>
         {Object.values(stages).map((stage,key)=>(
            <Pressable _hover={{color:"red.300"}}   onPress={e=>{
                console.log(showContentKey.key,key)
                if ( showContentKey.key === ""){
                setShowContent(true)
                } else if (showContentKey.key === key ){
                setShowContent(!showContent)
                } else {
                setShowContent(true)

                }
                setShowContentKey({title:stage.title,key:key})
}
                 } >
<HStack justifyContent='space-between' pl={6} pr={4} py={4} bg={ (showContentKey.title == stage.title && showContent)  ? 'blue.900':  "dark.100"}>
<Text  _hover={{color:'red.700'}} _text={{color:'white.500',fontSize:'17px'}} >{stage.title} {key===5 && state.Length ? `CH( ${scenes_formats.formats[state.Length.value].chapters} )`:""} {key===5 && state.Length ? `SC-CH( ${scenes_formats.formats[state.Length.value].scenes_per_chapter} )`:""} </Text>

{/*
    stage.status === 'done' ? 
     <span style={{background:'green',fontSize:'10px',color:'white',padding:2,paddingRight:4,paddingLeft:4,borderRadius:'4px',width:'80px',textAlign:'center'}} >
     COMPLETE <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8.00011 13L12.2278 16.3821C12.6557 16.7245 13.2794 16.6586 13.6264 16.2345L21.5001 6" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1892 12.2368L15.774 6.63327C16.1237 6.20582 16.0607 5.5758 15.6332 5.22607C15.2058 4.87635 14.5758 4.93935 14.226 5.36679L9.65273 10.9564L11.1892 12.2368ZM8.02292 16.1068L6.48641 14.8263L5.83309 15.6248L2.6 13.2C2.15817 12.8687 1.53137 12.9582 1.2 13.4C0.868627 13.8419 0.95817 14.4687 1.4 14.8L4.63309 17.2248C5.49047 17.8679 6.70234 17.7208 7.381 16.8913L8.02292 16.1068Z" fill="#ffffff"></path> </g></svg>
     </span> 
:     stage.status === 'progress' ? 
<svg   width="18px" height="18px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><g><path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path><path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></g></svg>
:
 <span style={{background:'gold',fontSize:'10px',color:'black',padding:2,paddingRight:4,paddingLeft:4,borderRadius:'4px',width:'80px',textAlign:'center'}} >PENDING</span>

*/}


</HStack>

 
{key===5 ? chapters.map((scene,key)=>(

<>
 
<VStack style={{borderBottom:'1px solid gray'}}  key={key} justifyContent='space-between' space={1} pl={12} pr={4} py={1}  bg={ (showContentKey.title == stage.title && showContent)  ? 'blue.900':  "dark.300"}>

<Text  _hover={{color:'red.700'}} _text={{color:'white.500',fontSize:'17px'}} >Chapter {scene.chapter}: {scene.chapter_title} </Text>
<Text  _hover={{color:'red.700'}} _text={{color:'white.500',fontSize:'17px'}} >Scene {scene.scene}  </Text>


</VStack>
</>

) ):<></>}

 
<Divider />
</Pressable>

            ))}

        </ScrollView>


</>)
}




const RenderTextContent=({label="",key=""})=>{


return ( 
<VStack  >
<Pressable onPress={e=>setShowContent(false)} >
<Center  > 
<HStack alignItems='center' space={8} >
<h4 style={{color:"white"}}><span  style={{fontSize:'28px',color:'white',marginRight:'13px',fontWeight:'bold'}}> 
<svg fill="#FFFFFF" width="24px" height="24px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M222.927 580.115l301.354 328.512c24.354 28.708 20.825 71.724-7.883 96.078s-71.724 20.825-96.078-7.883L19.576 559.963a67.846 67.846 0 01-13.784-20.022 68.03 68.03 0 01-5.977-29.488l.001-.063a68.343 68.343 0 017.265-29.134 68.28 68.28 0 011.384-2.6 67.59 67.59 0 0110.102-13.687L429.966 21.113c25.592-27.611 68.721-29.247 96.331-3.656s29.247 68.721 3.656 96.331L224.088 443.784h730.46c37.647 0 68.166 30.519 68.166 68.166s-30.519 68.166-68.166 68.166H222.927z"></path></g></svg> </span>  {label}  </h4> 
{String(showContentKey.key) !== '0' ? <Button mb={1} onPress={e=>reGenerate(showContentKey.key)} > Re-Generate</Button>:<> </>}

</HStack>
 </Center>
 </Pressable>
<Divider mb={6}  />

 
{ 
    String(showContentKey.key) === '0' ?

<RenderConfiguration state={state} showContent={showContent} 
setStep={setStep} setShowContent={setShowContent}  />
    :

state[stages[Number(showContentKey.key)].result] ? <EditablePre 
defaultValue={state[stages[Number(showContentKey.key)].result]} onSave={content=>{
    updateState(stages[Number(showContentKey.key)].result,content)
    update_stages(Number(showContentKey.key),'done')
}} ></EditablePre>
: <EditablePre onSave={content=>{
    updateState(stages[Number(showContentKey.key)].result,content)
        update_stages(Number(showContentKey.key),'done')
}} 
defaultValue={`No ${label}, Add/Edit manually or Re-Generate` }> </EditablePre>
}


</VStack>
)



}




const getButtonName=()=> step === 3 ? "Summarize":'Next'
const getLoadingName=()=> (String(currentStage) === '1' || String(currentStage) === '2') ?  "Title & Story Premise ..." : String(currentStage) === '3' ? "Back Cover Blub" :String(currentStage) === '4' ? "Story Introduction": String(currentStage) === '5' ? `Chapter ${chapter} and scene ${scene}` :("AI Loading ... "+currentStage)
const historyl = useHistory()

const [isLandScape, isPortrait] = useMediaQuery([{
    orientation: "landscape"
  }, {
    orientation: "portrait"
  }]);


    return (  
    <HStack minHeight='89vh' justifyContent='space-between' mt={isPortrait ?'8.5vh':0}  >
    <VStack space={3}  mx={ isPortrait ? 0: 8} w={ isPortrait ? "100%": "92%"}>
     <Steps items={step_json} type={'point'} flat={true}   />
  

  <div>
  <div style={{ display: showContent ? 'block' : 'none', }}>
    <RenderTextContent label={showContentKey.title} key={showContentKey.key} />
  </div>

  <div style={{ display: !showContent && isLoading ? 'block' : 'none' }}>
    <RenderLoading label={loading_label} />
  </div>

  <div style={{ display: !showContent && !isLoading && step === 1 ? 'block' : 'none' }}>
    {renderStepOne()}
  </div>

  <div style={{ display: !showContent && !isLoading && step === 2 ? 'block' : 'none' }}>
    {renderStepTwo()}
  </div>

  <div style={{ display: !showContent && !isLoading && step === 3 ? 'block' : 'none' }}>
    {renderStepThree()}
  </div>

  <div style={{ display: !showContent && !isLoading && step === 4 ? 'block' : 'none' }}>
    <RenderStepFour />
  </div>

  <div style={{ display: !showContent && !isLoading && step === 0 ? 'block' : 'none' }}>
    {renderHome()}
  </div>
</div>



{isLoading ? <></>:
<Center  style={{zIndex:-10, }} >
<HStack w='90%'  space={5} mt={3} mb={16} justifyContent='center' alignItems='center' >

  {step > 1  && !showContent ?  <button  className="big_button " onClick={e=>goback()} style={{
                        textDecoration:'none',minWidth:160,height:'45px',borderRadius:'17px'
                    }} > 
            <HStack w='100%' alignItems='center' justifyContent='center' space={1} >   <Text style={{fontSize:isPortrait ? '13px': '16px', fontWeight:"bold",color:'green' }}  >  Back </Text> </HStack>
             </button>:<></>}
              


   {   !isPortrait &&                
<a href='/'  className="big_button " style={{
                        textDecoration:'none',minWidth:160,height:'45px',borderRadius:'17px'
                    }} > 
            <HStack w='100%' alignItems='center' justifyContent='center' space={1} > 
           <svg   width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
  <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z"/>
</svg>
            <Text style={{fontSize:isPortrait ? '13px': '16px', fontWeight:"bold",color:'green' }}  >  Home </Text> </HStack>
             </a>
         }


        {!showContent ?  step != 4 &&     <button    className="big_button " onClick={e=>goforward()} style={{
                        textDecoration:'none' ,minWidth:160,height:'45px',borderRadius:'17px'
                    }} >
            <HStack w='100%' alignItems='center' justifyContent='center' space={1} ><Text style={{fontSize: isPortrait ? '13px': '16px',
            fontWeight:"bold",color:'green' }}  >  {getButtonName()} </Text>   </HStack>
 </button>:<></>}


 {  step == 4 &&  <>

{
    isPortrait ? 

 <VStack space={4} >    


                     <button    className="big_button" onClick={e=>{

setStep(1)
setShow(false)
setIsLoading(false)
setState({}) 
localStorage.removeItem('historyview') 
 historyl.push('/summarize')

 }} style={{
                      fontSize:isPortrait ? '13px': '16px',    textDecoration:'none' ,minWidth:160,height:'45px',borderRadius:'17px',fontFamily: "'Noto Sans JP', serif"
                    }} ><span>New <span> </span></span> </button> 


</VStack>


:
 <HStack space={4} >      <button    className="big_button" onClick={e=>{
 deleteContent(String(state.content).replace(/<\/?[^>]+(>|$)/g, "").slice(0,40))
    goforward(3)
 }} style={{
                     fontSize:isPortrait ? '13px': "16px",   textDecoration:'none' ,minWidth:160,height:'45px',borderRadius:'17px',fontFamily: "'Noto Sans JP', serif"
                    }} ><span>Re-Summarize<span> </span></span> </button> 


                     <button    className="big_button" onClick={e=>{

setStep(1)
setShow(false)
setIsLoading(false)
setState({}) 
localStorage.removeItem('historyview') 
 historyl.push('/summarize')

 }} style={{
                   fontSize:isPortrait ? '13px': "16px",       textDecoration:'none' ,minWidth:160,height:'45px',borderRadius:'17px',fontFamily: "'Noto Sans JP', serif"
                    }} ><span>New <span></span></span> </button> 


</HStack>
}

 </>






                }


      </HStack>
      </Center>
  }

      </VStack>
 </HStack>
   
    )
}

 // <VStack w='100%' >{isLoading ?  <Banner300by250 /> : <Banner728by90 />}</VStack>


















//   <Divider />
//         <HStack>





//         <VStack w='80%' >
//         {done ?
// <Center> {stages[step].result}  </Center>
//          :
//         <Center h='60vh' mt={3} >
// <h3 className='main-title'  >Generating {stages[step].title} </h3>
{<></>}
 


// </Center>
// }
//         </VStack>
//         </HStack>